import Button from '@atlaskit/button/new';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type ReactElement } from 'react';
import { CustomThemeButton } from '@atlaskit/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCog, faGauge, faGaugeMax } from '@fortawesome/pro-regular-svg-icons';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import { DateTime } from 'luxon';
import { type ApiApiKey } from 'gen/torch/v1/api';
import useAcceptRisk from 'mutations/useAcceptRisk';
import useNotifyOwner from 'mutations/useNotifyOwner';
import useReopenRisk from 'mutations/useReopenRisk';
import { ViolationSeverity_ViolationSeverityEnum as ViolationSeverity, ViolationType_ViolationTypeEnum as ViolationType } from 'gen/torch/v1/violation_engine';
import { getViolationDisplayName, getViolationVerb, SEVERITY_MAPPER, sortViolations } from 'utils/ViolationUtils';

function convertToDateTime (timestamp: Timestamp): DateTime {
  return DateTime.fromJSDate(Timestamp.toDate(timestamp));
}

export default function RiskInformation ({ accepted, apiKey }: {
  accepted: boolean
  apiKey: ApiApiKey
}): ReactElement {
  if (apiKey.violations.length === 0 || apiKey.lastRotatedAt == null || apiKey.lastUsed == null) {
    return <></>;
  }

  sortViolations(apiKey.violations);

  const violation = apiKey.violations[0];

  if (violation.type !== ViolationType.KEY_ROTATION_OVERDUE && violation.type !== ViolationType.ORPHANED_IDENTITY) {
    return <></>;
  }

  const { mutate: acceptRisk } = useAcceptRisk({});
  const { mutate: notifyOwner } = useNotifyOwner({});
  const { mutate: reopenRisk } = useReopenRisk({});

  const handleNotifyOwner = (): void => {
    notifyOwner({ violationStableId: violation.stableId });
  };
  const handleAcceptRisk = (): void => {
    acceptRisk({ violationStableId: violation.stableId });
  };
  const handleReopenRisk = (): void => {
    reopenRisk({ violationId: violation.id });
  };

  const getRelevantViolationTimeMetric = (): DateTime => {
    if (violation.type === ViolationType.KEY_ROTATION_OVERDUE) {
      if (apiKey.lastRotatedAt == null) {
        throw new Error('lastRotatedAt is null');
      }
      return convertToDateTime(apiKey.lastRotatedAt);
    }

    if (apiKey.lastUsed == null) {
      throw new Error('lastUsed is null');
    }
    return convertToDateTime(apiKey.lastUsed);
  };

  return (
    <Box
      style={{
        borderRadius: token('space.100'),
        backgroundColor: accepted
          ? token('color.background.neutral')
          : violation.severity < ViolationSeverity.HIGH
            ? token('color.background.accent.yellow.subtlest.hovered')
            : token('color.background.accent.red.subtler'),
        padding: token('space.200'),
        color: accepted
          ? token('color.text')
          : violation.severity < ViolationSeverity.HIGH
            ? token('color.text.accent.yellow.bolder')
            : token('color.text.accent.red'),
        gap: token('space.150'),
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        style={{
          textTransform: 'uppercase',
          font: token('font.heading.xxsmall'),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: token('space.050'),
          width: '100%'
        }}
      >
        <FontAwesomeIcon
          icon={accepted ? faCheck : (violation.severity < ViolationSeverity.HIGH ? faGauge : faGaugeMax)}
          style={{
            width: token('space.200'),
            height: token('space.200')
          }}
        />
        <Box
          style={{
            flex: 1
          }}
        >
          {SEVERITY_MAPPER[violation.severity]} risk{' '}
          {accepted ? 'accepted by user ' : ''}|{' '}
          {getViolationDisplayName(violation.type)}
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => {}}>
          <FontAwesomeIcon
            icon={faCog}
            style={{
              color: accepted
                ? token('color.text')
                : violation.severity < ViolationSeverity.HIGH
                  ? token('color.text.accent.yellow.bolder')
                  : token('color.text.accent.red'),
              width: token('space.200'),
              height: token('space.200')
            }}
          />
        </Box>
      </Box>
      <Box>
        This key has last been {getViolationVerb(violation.type)} {getRelevantViolationTimeMetric().toRelative({ unit: 'days' })}.
        It should be {getViolationVerb(violation.type)} once every {violation.daysUntilTriggered} days.
        It should have been {getViolationVerb(violation.type)} {
        getRelevantViolationTimeMetric().plus({
          days: violation.daysUntilTriggered
        }).toRelative({ unit: 'days' })}.
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: token('space.025')
        }}
      >
        {accepted
          ? (<CustomThemeButton
            style={{
              backgroundColor: 'transparent',
              border: `1px solid ${token('color.border.bold')}`,
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={handleReopenRisk}>Re-open the risk</CustomThemeButton>)
          : (
          <>
            <Button
              appearance={
                violation.severity < ViolationSeverity.HIGH
                  ? 'warning'
                  : 'danger'
              }
              onClick={handleNotifyOwner}
            >
              Notify Owner
            </Button>
            <CustomThemeButton
              style={{
                backgroundColor: 'transparent',
                color: `${
                  violation.severity < ViolationSeverity.HIGH
                    ? token('color.text.accent.yellow.bolder')
                    : token('color.text.accent.red')
                } !important`
              }}
              onClick={handleAcceptRisk}
            >
              Accept Risk
            </CustomThemeButton>
          </>)}
      </Box>
    </Box>
  );
}
