import { useQuery, type UseQueryResult } from 'react-query';
import { useApi } from 'contexts/ApiProvider';
import { Timestamp } from 'gen/google/protobuf/timestamp';
import { DateTime } from 'luxon';
import LocalStorage from 'LocalStorage';
import SNAPSHOT_STARTED_AT_MOCK from 'mocks/demo/useLastUpdateRelativeDateMock';

export const SNAPSHOT_STARTED_AT_QUERY_KEY = 'snapshotStartedAt';

const getRelativeDate = (date: Date): string | null => {
  return DateTime.fromJSDate(date).toRelative();
};

const useLastUpdateRelativeDate = (): UseQueryResult<string | null, Error> => {
  const api = useApi();

  return useQuery<string | null, Error>(
    SNAPSHOT_STARTED_AT_QUERY_KEY,
    async () => {
      let startedAt: Timestamp | undefined;
      if (LocalStorage.getUser()?.isDemo === true) {
        startedAt = SNAPSHOT_STARTED_AT_MOCK.startedAt;
      } else {
        const response = await api.getSnapshotStartedAt({}).response;
        startedAt = response.startedAt;
      }
      if (startedAt === undefined) return null;

      const date = Timestamp.toDate(startedAt);
      return getRelativeDate(date);
    },
    {
      onError: (e: any) => {
        console.error('Error has occurred while fetching last update date', e);
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false
    }
  );
};

export default useLastUpdateRelativeDate;
