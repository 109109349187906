import { useApi } from 'contexts/ApiProvider';
import {
  type SetOwnerResponse,
  type ParentType_ParentTypeEnum,
  type OwnerType_OwnerTypeEnum
} from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import { AWS_SSO_USERS_QUERY_KEY } from 'queries/useAwsSsoUsers';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';

interface SetOwnerParams {
  parentType: ParentType_ParentTypeEnum
  parentStableId: string
  ownerType: OwnerType_OwnerTypeEnum
  ownerStableId?: string
  ownerText?: string
}

interface UseSetOwnerProps {
  onSuccess?: (
    ownerType: OwnerType_OwnerTypeEnum,
    ownerStableId: string,
    ownerText: string
  ) => void
  onError?: (e: Error) => void
}

const useSetOwner = ({
  onSuccess,
  onError
}: UseSetOwnerProps): UseMutationResult<
SetOwnerResponse,
unknown,
SetOwnerParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError = 'Error has occurred in api setOwner';

  return useMutation(
    async ({
      parentType,
      parentStableId,
      ownerType,
      ownerStableId = '',
      ownerText = ''
    }: SetOwnerParams): Promise<SetOwnerResponse> => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return {
          success: true,
          ownerType,
          ownerStableId,
          ownerText
        };
      }

      await queryClient.cancelQueries([AWS_SSO_USERS_QUERY_KEY]);
      return await api.setOwner({
        parentType,
        parentStableId,
        ownerType,
        ownerStableId,
        ownerText
      }).response;
    },
    {
      onSuccess: (resp: SetOwnerResponse) => {
        if (resp?.success) {
          onSuccess?.(resp.ownerType, resp.ownerStableId, resp.ownerText);
        } else {
          console.error(apiError);
          onError?.(new Error(apiError));
        }
      },
      onError: (e: Error) => {
        console.error(apiError, e);
        onError?.(e);
      }
    }
  );
};

export default useSetOwner;
