import { useApi } from 'contexts/ApiProvider';
import { type Empty } from 'gen/google/protobuf/empty';
import { type ApiApiKey } from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import { API_KEYS_QUERY_KEY } from 'queries/useApiKeys';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';

interface ReopenRiskParams {
  violationId: ApiApiKey['violations'][number]['id']
}

interface UseReopenRiskProps {
  onSuccess?: () => void
  onError?: (e?: Error) => void
}

const useReopenRisk = ({
  onSuccess,
  onError
}: UseReopenRiskProps): UseMutationResult<
Empty,
Error,
ReopenRiskParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError = 'Error has occurred in api reopenRisk';

  return useMutation(
    async ({ violationId }: ReopenRiskParams): Promise<Empty> => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return {
          success: true
          // Note - When an onSuccess is being implement - make sure to pass here the necessary parameters it expects
        };
      }

      await queryClient.cancelQueries([API_KEYS_QUERY_KEY]);
      return await api.reopenRisk({
        violationStableId: violationId
      }).response;
    },
    {
      onSuccess,
      onError: (e: Error) => {
        console.error(apiError, e);
        onError?.(e);
      }
    }
  );
};

export default useReopenRisk;
