import { useQuery, type UseQueryResult } from 'react-query';
import { useApi } from 'contexts/ApiProvider';
import { type ApiApiKey } from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import API_KEYS_MOCK from 'mocks/demo/useApiKeysMock';

export const API_KEYS_QUERY_KEY = 'apiKeys';

const useApiKeys = (): UseQueryResult<ApiApiKey[], Error> => {
  const api = useApi();

  return useQuery<ApiApiKey[], Error>(
    API_KEYS_QUERY_KEY,
    async () => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return API_KEYS_MOCK;
      }
      const response = await api.listApiKeysCurrent({}).response;
      return response.results;
    },
    {
      onError: (e: any) => {
        console.error('Error has occurred in api list API keys', e);
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false
    }
  );
};

export default useApiKeys;
