import { Box } from '@atlaskit/primitives';
import { type ICellRendererParams } from 'ag-grid-community';
import { ParentType_ParentTypeEnum, type ApiApiKey } from 'gen/torch/v1/api';
import useSetNote from 'mutations/useSetNote';
import { writeToNodeAndRefresh } from 'utils/AgGridUtils';
import { toast } from 'react-toastify';
import {
  type SetStateAction,
  useState,
  type Dispatch,
  type ReactElement,
  type PropsWithChildren,
  useLayoutEffect,
  type ChangeEvent
} from 'react';
import Popup from '@atlaskit/popup';
import NoteIcon from 'assets/icons/NoteIcon';
import { token } from '@atlaskit/tokens';
import TextArea from '@atlaskit/textarea';
import Button from '@atlaskit/button/new';
import Tooltip from 'components/Tooltip';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { IDENTIFIERS, useDrawer } from 'contexts/DrawerProvider';
import ApiKeysDrawer from 'components/Drawers/ApiKeysDrawer/ApiKeysDrawer';

const GRID_SELECTOR = '.ag-body-viewport.ag-layout-normal';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSubmit?: () => void
  title: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
}

const HoverWrapper = styled.div`
  width: ${token('space.400')};
  height: ${token('space.400')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -0.5rem;
  cursor: pointer;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

function NoteDialog ({
  isOpen,
  setIsOpen,
  onSubmit,
  children,
  title,
  value,
  setValue
}: ModalProps): ReactElement {
  return (
    <Popup
      onClose={() => {
        setIsOpen(false);
      }}
      strategy="absolute"
      placement="top-start"
      trigger={(triggerProps) => <Box {...triggerProps}>{children}</Box>}
      content={() => (
        <Box
          style={{
            width: '360px',
            height: '224px',
            padding: token('space.200'),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: token('space.200')
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: token('space.100'),
              font: token('font.heading.small')
            }}
          >
            <NoteIcon />
            {title}
          </Box>
          <Box
            style={{
              flex: 1,
              alignSelf: 'stretch',
              display: 'flex',
              overflowY: 'auto'
            }}
          >
            <TextArea
              maxHeight="100%"
              name="note"
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              value={value}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setValue(e.target.value);
              }}
            />
          </Box>
          <Button appearance="primary" onClick={onSubmit}>
            Save
          </Button>
        </Box>
      )}
      isOpen={isOpen}
    />
  );
}

function NoteRenderer ({
  value,
  data,
  api: gridApi,
  node,
  context
}: ICellRendererParams<ApiApiKey, string, ApiApiKey[]>): JSX.Element {
  const [noteValue, setNoteValue] = useState<string>(value ?? '');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { openDrawer } = useDrawer();

  // Lock table scroll when modal is open
  useLayoutEffect(() => {
    const element = document.querySelectorAll(GRID_SELECTOR)?.[0];
    setTimeout(() => {
      if (isModalOpen) {
        element?.classList.add('no-scroll');
      }
    }, 0);

    return () => {
      element?.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  const { mutate: setNote } = useSetNote({
    onSuccess: () => {
      if (node != null) {
        writeToNodeAndRefresh<ApiApiKey>({
          node,
          gridApi,
          key: 'note',
          value: noteValue
        });
      }
    },
    onError: () => {
      toast.error(`Error setting note for accessKey ${data?.identifier}`);
    }
  });

  const onAddNote = (): void => {
    setNote({
      parent: data,
      parentType: ParentType_ParentTypeEnum.API_KEYS,
      note: noteValue
    });
    setIsModalOpen(false);
  };

  const openNoteModal = (): void => {
    setIsModalOpen(true);
  };

  const openApiKeysDrawer = (): void => {
    if (data !== undefined) {
      openDrawer(
        IDENTIFIERS.API_KEYS_DRAWER,
        <ApiKeysDrawer
          apiKeys={context}
          initialApiKey={data}
          gridParams={{
            api: gridApi,
            node
          }}
        />
      );
    }
  };

  if (value !== null && value !== '') {
    return (
      <Box
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Tooltip content={noteValue} position="left" variant="secondary">
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'flex-start',
              cursor: 'pointer'
            }}
          >
            <Box onClick={openApiKeysDrawer}>
              <NoteIcon />
            </Box>
          </Box>
        </Tooltip>
      </Box>
    );
  }
  return (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      <NoteDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={onAddNote}
        title="Add notes"
        value={noteValue}
        setValue={setNoteValue}
      >
        <Tooltip content="Add comment" position="left">
          <HoverWrapper style={{}} onClick={openNoteModal}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                color: token('color.text.brand')
              }}
            />
          </HoverWrapper>
        </Tooltip>
      </NoteDialog>
    </Box>
  );
}

export default NoteRenderer;
