import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const COLOR_MAPPER: Record<string, ReturnType<typeof token>> = {
  success: token('color.icon.accent.green')
};

export default function StatusIndicator ({
  status
}: {
  status: keyof typeof COLOR_MAPPER
}): JSX.Element {
  return (
    <Box
      style={{
        borderRadius: '100%',
        backgroundColor: COLOR_MAPPER[status],
        width: token('space.100'),
        height: token('space.100')
      }}
    />
  );
}
