import { useApi } from 'contexts/ApiProvider';
import { type Empty } from 'gen/google/protobuf/empty';
import { type ApiApiKey } from 'gen/torch/v1/api';
import LocalStorage from 'LocalStorage';
import { API_KEYS_QUERY_KEY } from 'queries/useApiKeys';
import {
  useMutation,
  useQueryClient,
  type UseMutationResult
} from 'react-query';

interface NotifyOwnerParams {
  violationStableId: ApiApiKey['violations'][number]['stableId']
}

interface UseNotifyOwnerProps {
  onSuccess?: () => void
  onError?: (e?: Error) => void
}

const useNotifyOwner = ({
  onSuccess,
  onError
}: UseNotifyOwnerProps): UseMutationResult<
Empty,
Error,
NotifyOwnerParams,
unknown
> => {
  const queryClient = useQueryClient();
  const api = useApi();

  const apiError = 'Error has occurred in api notifyOwner';

  return useMutation(
    async ({ violationStableId }: NotifyOwnerParams): Promise<Empty> => {
      if (LocalStorage.getUser()?.isDemo === true) {
        return {
          success: true
          // Note - When an onSuccess is being implement - make sure to pass here the necessary parameters it expects
        };
      }

      await queryClient.cancelQueries([API_KEYS_QUERY_KEY]);
      return await api.notifyOwner({
        violationStableId
      }).response;
    },
    {
      onSuccess,
      onError: (e: Error) => {
        console.error(apiError, e);
        onError?.(e);
      }
    }
  );
};

export default useNotifyOwner;
